import React from "react"
import { graphql } from "gatsby"
import useHubspotForm from "@aaronhayes/react-use-hubspot-form"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { useMediaQuery, Context as ResponsiveContext } from "react-responsive"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./lets-talk.module.scss"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import ContactMobileBanner from "../components/ContactMobileBanner"

// Content
import JSONData from "../../content/contact-us.json"

const MinWidth768 = ({ children }) => {
  const isMin768px = useMediaQuery({ minWidth: 768 })
  return isMin768px ? children : null
}

const ContactPage = ({ data }) => {
  useHubspotForm({
    portalId: "6202075",
    formId: "18e37de8-b997-4ed3-b27b-12d5a7a12c00",
    target: "#tp-hubspot-form",
  })

  const {
    seo_title,
    seo_description,
    banner_title,
    form_header_text,
    form_side_title,
    form_side_text,
    form_contacts,
  } = JSONData

  return (
    <Layout>
      <SEO
        title={seo_title}
        description={seo_description}
        image={data.seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.headerBg.publicURL});
          }
        `}
      >
        <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
          <ContactMobileBanner title={banner_title} />
        </ResponsiveContext.Provider>
        <Container className={styles.bannerContainer}>
          <Row>
            <Col>
              <h1>{banner_title}</h1>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className={styles.formSection}>
        <Container className={styles.formContainer}>
          <Row>
            <Col className={styles.formHeader}>
              <p>{form_header_text}</p>
            </Col>
          </Row>
          <Row>
            <MinWidth768>
              <Col xs={5} className={styles.detailsColumn}>
                <Img
                  fluid={data.formImage.childImageSharp.fluid}
                  className="d-block w-100"
                  alt={banner_title}
                />
                <h5>{form_side_title}</h5>
                <p>{form_side_text}</p>
                <div className={styles.contactDetails}>
                  {form_contacts.map((item, i) => (
                    <a
                      key={i}
                      href={item.href_link}
                      target={item.target}
                      rel={item.rel}
                    >
                      <span>
                        <Img
                          fluid={data[item.contact_icon].childImageSharp.fluid}
                          className={styles.contactIcon}
                          alt={item.icon_alt}
                        />
                      </span>{" "}
                      {item.label}
                    </a>
                  ))}
                </div>
              </Col>
            </MinWidth768>
            <Col md={7}>
              <div id="tp-hubspot-form" className="embed-responsive" />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    seoBanner: file(relativePath: { eq: "banner/seo-contact-us.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    headerBg: file(relativePath: { eq: "banner/background-2.svg" }) {
      publicURL
    }

    # Form Assets
    formImage: file(relativePath: { eq: "services/client-management/01.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    addressPinIcon: file(relativePath: { eq: "address-pin.png" }) {
      childImageSharp {
        fluid(maxHeight: 85, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    mailIcon: file(relativePath: { eq: "mail.png" }) {
      childImageSharp {
        fluid(maxHeight: 85, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    mobilePhoneIcon: file(relativePath: { eq: "mobile-phone.png" }) {
      childImageSharp {
        fluid(maxHeight: 85, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    telephoneIcon: file(relativePath: { eq: "telephone.png" }) {
      childImageSharp {
        fluid(maxHeight: 85, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    viberIcon: file(relativePath: { eq: "viber.png" }) {
      childImageSharp {
        fluid(maxHeight: 85, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    whatsappIcon: file(relativePath: { eq: "whatsapp.png" }) {
      childImageSharp {
        fluid(maxHeight: 85, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
